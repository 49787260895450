<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h1>Link Útil</h1>
        <v-form @submit.prevent="submit()">
          <v-text-field
            v-model="linksUteis.titulo"
            label="Titulo"
            name="titulo"
            type="text"
            required
            :rules="rules"
          />
          <v-text-field
            v-model="linksUteis.subtitulo"
            label="Subtítulo"
            name="subtitulo"
            type="text"
            required
            :rules="rules"
          />
          <v-text-field
            v-model="linksUteis.link"
            label="Link"
            name="link"
            type="text"
          />
          <v-text-field
            v-model="linksUteis.thumbnailUrl"
            label="Thumbnail Url"
            name="thumbnailurl"
            type="text"
          />
          <v-checkbox
            v-model="linksUteis.ativo"
            label="Ativo"
            name="ativo"
          />

          <v-btn
            color="primary"
            type="submit"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      linksUteis: {},
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
    }),
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
    },
    methods: {
      load () {
        this.$http.get(`/linksuteis/${this.$route.params.id}`)
          .then(resp => {
            this.linksUteis = resp.data
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      submit () {
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      getFormData () {
        if (this.linksUteis.ativo === true) { this.linksUteis.ativo = 1 }
        if (this.linksUteis.ativo === false) { this.linksUteis.ativo = 0 }
        const formData = new FormData()
        formData.append('titulo', this.linksUteis.titulo)
        formData.append('subtitulo', this.linksUteis.subtitulo)
        formData.append('link', this.linksUteis.link)
        formData.append('thumbnailurl', this.linksUteis.thumbnailUrl)
        formData.append('ordem', this.linksUteis.ordem)
        formData.append('ativo', this.linksUteis.ativo)
        return formData
      },
      post () {
        this.$http.post('/linksuteis', this.getFormData(), { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/linksuteis')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      put () {
        if (this.linksUteis.ativo === true) { this.linksUteis.ativo = 1 }
        if (this.linksUteis.ativo === false) { this.linksUteis.ativo = 0 }
        this.$http.put('/linksuteis/', this.linksUteis)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/linksuteis')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>
